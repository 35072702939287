body.login {
  padding-bottom: $gutter-large;
  padding-top:0;
  background-color: $gray-lighter;

  .navbar {
    margin-bottom: $gutter-large;
  }
    
    .logo {
      margin-bottom:$gutter-large;
    }

    small {
      display:block;
      margin-top: $gutter-large;
    }
}


@media (min-width: 768px) {
  .login {
    .well {
      width:560px;
      border-radius: 0;
      padding: $gutter-large 130px;
      @include box-shadow(0 2px 8px rgba(0,0,0,.15));
    }
  } 
}


.form-signin {
  max-width: 330px;
  // padding: 15px;
  margin: 0 auto;

  .input[type="submit"] {
    
  }


}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: 12px;
}
.form-signin input[type="password"] {
  margin-bottom: 56px;
}