.alert {
	border: none;
}

.alert-top-right {
	h4 {
		border-bottom: 1px solid $lightest;
		padding-bottom: 10px;

		small {
			float:right;
			margin-top:4px;
			a {
				color: $lightest;
			}
		}
	}
}

@media (min-width: $screen-sm) {

	.alert-top-right {
		position: absolute;
		min-width: 300px;
		top:0;
		right:0;
	}
}