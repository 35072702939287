
//bootstrap overrides
$brand-primary:         #0096d6; //blue
$brand-info:            #3cb43c; //green
$brand-success:         #3cb43c; //green
$brand-warning:         #ffb400; //orange
$brand-danger:          #f03c3c; //red

$gray-lighter:          #fafafa; //grey
$lightest: #fff;


//nav
$navbar-default-bg:                 $brand-primary;
$navbar-default-color:              #ffffff;
$navbar-default-link-color:         #ffffff;
$navbar-default-link-hover-color:   #eee;
$navbar-border-radius: 0;
$navbar-height: 80px;

//form
$border-radius-large: 4px;
$input-border-radius: 4px;

//alerts & form states
$state-success-text:             #3c763d !default;
$state-success-bg:               $brand-success;

// $state-info-text:                #31708f !default;
// $state-info-bg:                  $brand-info;

$state-warning-text:             $lightest !default;
$state-warning-bg:               $brand-warning;

$state-danger-text:              $lightest !default;
$state-danger-bg:                $brand-danger;

//buttons
$btn-default-bg: #999;
$btn-default-color: $lightest;

//type
$font-family-sans-serif: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight: 400;



//tables
$table-bg-accent: #f9f9f9;

//new colors
$brand-secondary:       #0078b4; //blue2
$brand-highlight:       #ff1e5a; //pink
$brand-cyan:			#005a80; //cyan

//font paths
$roboto-font-path:      '/fonts/roboto';

//wells
$well-bg: $lightest;


/*==========  Custom vars  ==========*/

$gutter-large: 40px;