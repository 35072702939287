.header-buttons {
    border-bottom: 1px solid #eee;
    margin-bottom: 40px;
    padding-bottom:15px;

    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .btn {
        margin-top:0;
        margin-bottom: 0;
    }

    form {
        margin-top: 5px;
        margin-bottom: 0px;
    }


}

.navbar-brand {
    padding:20px 15px;
    > img {
        display:inline;
    }
}

@media (min-width:$screen-sm) {
    .navbar-nav {
        display: none;
    }
}
