.table {
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: middle;
            }
        }
    }

    .col-checkbox {
        width: 4%;
    }
}

.table-actions {
    margin-top: 30px;
    margin-bottom: 20px;
    .pagination {
        margin:10px 0;
    }
}

.sub-table-actions {
    margin-top: 20px;
    margin-bottom: 20px;
}

.sub-table {
    margin-top: 30px;
}

tr.disabled {
    color: #aaa;
    background-color: #fafafa;
}

tr.sortable-ghost {
    border: 1px solid $gray-lighter;
    background-color: $brand-primary;
    color:$lightest;
}

tr .glyphicon-move {
     cursor: move;
     cursor: -webkit-grabbing;
}

.table-progressions {
    margin-bottom: $gutter-large;
}