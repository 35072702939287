.btn, a.btn {
    text-transform: uppercase;
}

.table {

    .btn {
        padding: 8px 7px;
        margin-top: 2px;
        margin-bottom: 2px;

    }

    .btn.btn-hover {
        background-color: transparent;
        border:none;
        color: rgba(0, 0, 0, 0.3);

        &.btn-hover:hover {
            color: white;
        }

        &.btn-danger:hover {
            background-color: #f44336;
        }
        
        &.btn-primary:hover {
            background-color: $brand-primary;
        }

        &.btn-info:hover {
            background-color: $brand-info;
        }
    }
}


.header-buttons {
    .btn {
        padding: 10px 40px;
    }

    .buttons {
        margin-top: 10px;
    }

    @media (min-width:$screen-sm){
        .buttons {
            text-align: right;
            margin-top: 0;
        }   
    }

}

.toggle-buttons {
    display:inline-block;
}