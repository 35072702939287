h2, .h2, h3, .h3 {
	color: $brand-primary;
}

h3, .h3 {
	margin-bottom: $gutter-large;
}

h4, .h4 {
	font-weight: 700;
	text-transform: uppercase;
}