.content-block {
	&:last-child {border-bottom:none;}
    border-bottom: 1px solid #eee;
    margin-bottom: $gutter-large;
    padding-bottom: $gutter-large;
    position: relative;
}

.progression-list {

}
