
@media (min-width: $screen-lg) {
    .form-group-inline {
        .form-group {
            float:left;
            min-width: 200px;
            margin-right:20px;
            margin-bottom: 0;
        }

    }
}